export const caffeVariants = {
  blends: ["", "classic", "ospite", "partenopeo", "pulcinella"],
  waffelsSize: ["", 44, 38],
};

export const gogl = {
  blends: ["", "riceta1", "ricetta2"],
};

export const time = {
  hour: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24,
  ],
  minute: [0, 10, 20, 30, 40, 50],
};
