import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";

import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";
import TableData from "../../components/TableData";
import CardContainerHoc from "../../hoc/card-container-hoc";

const tableConstr = {
  title: "Lista parametri",
  footer: "Numero parametri qualità",
  thead: [
    "Cod.",
    "macinatura",
    "diametro cialda",
    "peso",
    "tempo di erogazione",
    "rulli macinino",
    "azioni",
  ],
  action: "nuovo/parametro",
};

const QualityParams = () => {
  const [params, setParams] = useState([]);

  async function callApi() {
    try {
      const rulesData = await API.get("apilucaffe", `/params/rule`);
      const filtredData = rulesData.filter((item) =>
        item.flag !== "del" ? item : null
      );
      setParams(filtredData);
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteParams(ID) {
    try {
      await API.patch("apilucaffe", `/params/flag/rule/${ID}`);
      callApi();
    } catch (err) {
      console.log(err);
    }
  }

  function handleDelete(item) {
    deleteParams(item);
    console.log("delete params:", item);
  }

  useEffect(() => {
    callApi();
  }, []);

  return (
    <CardContainerHoc title='Parametri qualità' noBorder>
      <TableData
        constructor={tableConstr}
        data={params}
        handleDelete={handleDelete}
      />
    </CardContainerHoc>
  );
};

export default QualityParams;
