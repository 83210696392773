import React from "react"
import {Link} from "react-router-dom"

import { ListGroup, ListGroupItem } from 'reactstrap';


const Storico = () => 
    (<>
        <h1 className="text-center mb-4">Storico</h1>

            <ListGroup>
                <Link className={"text-decoration-none"} to={{pathname: "/storico/produzione"}} >
                    <ListGroupItem>
                        <h5>Produzione</h5>
                    </ListGroupItem>
                </Link>
                <Link className={"text-decoration-none"}  to={{pathname: "/storico/qualità", state: {title: "Storico qualità"}}}>
                    <ListGroupItem>
                        <h5>Qualità</h5>
                    </ListGroupItem>
                </Link>
            </ListGroup>
    </>)


export default Storico