import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";

export default function CardContainerHoc(props) {
  const history = useHistory();
  return (
    <Card className={props.noBorder && "border-light"}>
      <CardBody>
        <CardTitle>
          <h1 className='text-center mb-4'>
            {props.title}
            <Button
              close
              onClick={() => history.goBack()}
              className={"text-danger"}
              aria-label='Close'
            >
              <Icon icon={close} size={32} />
            </Button>
          </h1>
        </CardTitle>
        {props.children}
      </CardBody>
    </Card>
  );
}
