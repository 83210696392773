import React, {Component} from 'react';
import {API} from 'aws-amplify'

import {Link} from 'react-router-dom';
import {Icon} from 'react-icons-kit';
import uuid from 'uuid/v4'
import {close} from 'react-icons-kit/ikons/close';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';



export default class AddOperator extends Component {
    constructor(props){
        super(props)
        this.state= {
            'freshman': '',
            'adress': {},
            'name': '',
            'surname': '',
            "job": ""
        }
    }

    extractNumber(str, label) { 
        var matches = str.match(/(\d+)/)
        console.log(matches[0])
        return matches[0]
    }
    
    onSubmitForm(){
        const ts = new Date()
        const payload = {
            body: {
            typeOfParams: "operator",
            ID: uuid(),
            freshman: this.state.freshman,
            name: this.state.name,
            surname: this.state.surname,
            adress : this.state.adress,
            job: this.state.job,
            creationDate: ts.getTime()
        }}
        this.putItem(payload)
        console.log(payload)
        console.log("submit")
        this.setState({
            'freshman': '',
            'adress': {},
            'name': '',
            'surname': '',
            "job": ""
        })
        this.props.history.push('/impostazioni/operatori')
    }

    async putItem(data){

        API.post("apilucaffe", "/params" , data)
            .then(res=>{
            console.log(res)
            })
            .catch((err)=> console.log(err))
    }

    render() {
        const {freshman, name, surname, job} = this.state
        const disabled =     freshman.length > 2 
                            && name.length > 2
                            && surname.length > 2
        console.log(disabled)
                
        return(
            <>
                <h1 className="text-center mb-4">
                    Nuovo operatore
                    <Link className={"text-danger float-right"} to={{pathname: "/impostazioni/parametriQualita"}}>
                        <Icon icon={close} size={32}/>
                    </Link>
                </h1>
                <Form>
                    <FormGroup>
                        <Label for="operatorName">Nome</Label>
                        <Input 
                            type="text" 
                            name="operatorName" 
                            id="operatorName" 
                            placeholder="Nome operatore" 
                            value={name}
                            onChange={(e)=>this.setState({name: e.target.value})}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label for="operatorSurname">Cognome</Label>
                        <Input 
                            type="text" 
                            name="operatorSurname" 
                            id="operatorSurname" 
                            placeholder="Cognome operatore" 
                            value={surname}
                            onChange={(e)=>this.setState({surname: e.target.value})}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label for="operatorFreshman">Matricola</Label>
                        <Input 
                            type="text" 
                            name="operatorFreshman" 
                            id="operatorFreshman" 
                            placeholder="Matricola operatore" 
                            value={freshman}
                            onChange={(e)=>this.setState({freshman: e.target.value})}
                            />
                    </FormGroup>
                    <FormGroup>
                        <Label for="operatorFreshman">Mansione</Label>
                        <Input 
                            type="text" 
                            name="operatorJob" 
                            id="operatorJob" 
                            placeholder="Mansione operatore" 
                            value={job}
                            onChange={(e)=>this.setState({job: e.target.value})}
                            />
                    </FormGroup>
                    <Button 
                        outline color="primary" 
                        className="float-right m-4"
                        onClick={()=>{this.onSubmitForm()}}
                        disabled={!disabled}
                    >Salva</Button>
                </Form>
            </>
        )
    }


}