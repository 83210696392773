/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "lucafeapp-20190815063101-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://lucafeapp-20190815063101-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-west-1:af988ed5-f194-448c-917f-dc4a689e3996",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_1C5BVS0ol",
    "aws_user_pools_web_client_id": "61neir4ct6t9o0kifjqpialujl",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "paramstable-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "collectorData-prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apilucaffe",
            "endpoint": "https://4o8kb380tj.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apiGetProdData",
            "endpoint": "https://vm5wsjs3gi.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
