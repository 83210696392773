import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { utility } from "../utility/utilities";
import { readDailyHourProduct } from "../controller/dailyProductController";

import { SingleDatePicker } from "react-dates";
import { FormGroup, Label, Input, Button, Spinner } from "reactstrap";
import Dailydata from "../components/Dailydata";
import CardContainerHoc from "../hoc/card-container-hoc";

import data from "../assets/data/machine";

export default class StoricoProd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: [],
      selectMachine: null,
      date: null,
      selectedDate: null,
      focused: false,
      spinner: false,
      oggi: new Date(),
      dailyProduction: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    console.log("length : " + this.state.dailyProduction.length);
    this.setState({ machines: data.machine });
  }
  UNSAFE_componentWillUnmount() {
    this.setState({
      machines: [],
      selectMachine: null,
      dailyProduction: [],
    });
  }
  handleChange(e) {
    const selected = this.state.machines.filter((machine) => {
      return machine.name === e.target.value;
    });

    console.log(selected[0].serialNumber);
    this.setState({
      selectMachine: selected[0].serialNumber,
      machineType: selected[0].type,
      dailyProduction: [],
    });
  }

  getChart() {
    var that = this;
    const selectedDate = moment(this.state.date).format("YYYY-MM-DD");
    readDailyHourProduct(
      that.state.machineType,
      `${that.state.selectMachine}_COUNTER`,
      selectedDate,
      (data) => {
        console.log("return data")
        console.log(data)
        // console.log(utility.insInDailyArray(data));
        that.setState({
          dailyProduction: utility.insInDailyArray(data),
          selectedDate,
          spinner: false,
        });
      }
    );
  }

  render() {
    let data = this.state.date ? this.state.date.format("DD-MM-YYYY") : null;
    if (this.state.selectMachine && data) {
      console.log(this.state.selectMachine, data);
    }
    const option = this.state.machines.map((machine) => {
      if (machine.fourDotZero) {
        return <option key={machine.serialNumber}>{machine.name}</option>;
      }
      return null;
    });

    return (
      <CardContainerHoc title='Storico Produzione'>
        {/* ref previsto refactoring aggiungendo componente card */}

        <h6 className='py-4'>
          In questa pagina puoi consultare lo storico delle produzioni
          giornaliere per macchina
        </h6>
        <FormGroup className='d-flex flex-row w-50 justify-content-between'>
          <Label for='exampleSelect'>Seleziona una macchina :</Label>
          <Input
            type='select'
            name='select'
            id='selectMachine'
            className='ml-3 w-50'
            onChange={this.handleChange}
          >
            <option></option>
            {option}
          </Input>
        </FormGroup>
        <FormGroup className='d-flex flex-row align-items-center w-50 justify-content-between'>
          <p className='mb-0 mr-3'>Seleziona una data :</p>
          <SingleDatePicker
            showClearDate
            reopenPickerOnClearDate
            isOutsideRange={(date) => date > new Date()}
            placeholder='Data'
            displayFormat={"DD-MM-YYYY"}
            id='date'
            small
            numberOfMonths={1}
            monthFormat={"MMMM YYYY"}
            date={this.state.date}
            onDateChange={(date) => {
              this.setState({ date });
            }}
            focused={this.state.focused}
            onFocusChange={({ focused }) => {
              this.setState({ focused });
            }}
          />
        </FormGroup>

        <Button
          className='float-right'
          onClick={() => {
            this.getChart();
            this.setState({ date: null, spinner: true });
          }}
        >
          Invia Richiesta
        </Button>
        <div className='w-100 d-flex justify-content-center mt-5'>
          {this.state.spinner && <Spinner type='grow' color='warning' />}
        </div>
        {this.state.dailyProduction.length === 0 ? null : (
          <Dailydata
            dailyProduction={this.state.dailyProduction}
            productionDate={this.state.selectedDate}
            machineType={this.state.machineType}
          />
        )}
      </CardContainerHoc>
    );
  }
}
