import React from 'react'
import { Link } from 'react-router-dom'

import MachineState from "../components/MachineState"
import { Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, CardFooter, Button } from 'reactstrap'

const CardMachine = (props) => {
  return (
      <Card className="mb-2">
        <div className="row pt-2 pl-2">
          <div className="col-2">
            <CardImg top width="100%" src={props.image} alt="Card image cap" />
            <MachineState machineState={props.fourDotZero ? props.machineState: {state:"4.0 off"}}/> 
            
          </div>
          <div className="col-10">
            <CardBody className="d-flex flex-column">
              <CardTitle><h1>{props.machineName}</h1></CardTitle>
            <CardSubtitle>Costruttore: {props.manufacturer}, Serial nr. {props.serialNumber}, Anno di costruzione: {props.yearOfMake} </CardSubtitle>
            <CardText>{props.machineDescription}</CardText>
            <CardText>ubicazione: {props.localization}</CardText>
            <CardFooter
              className = "bg-white"
            >
              <Link to={{
                pathname: "/dettagli_produzione",
                state: {machine: props},
              }}>
                <Button 
                  className="align-self-end mx-2"
                  outline
                >Dettagli produzione</Button>
              </Link>
              <Link to={{
                pathname: "/pianificazione_produzione",
                state: {machine: props},
              }}>
                <Button 
                  className={`align-self-end mx-2 `}
                  disabled={!props.fourDotZero}
                  outline
                >Pianificazione produzione</Button>
              </Link>
            </CardFooter>
            
          </CardBody>
          </div>  
        </div>
      </Card>
  );
};

export default CardMachine;