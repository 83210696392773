import React, { Component } from "react";
import { API } from "aws-amplify";

import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import uuid from "uuid/v4";
import { close } from "react-icons-kit/ikons/close";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

import data from "../assets/data/recipe";

export default class AddRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cod: "",
      millingName: "",
      waffelDiameter: "",
      waffelWeight: "",
      timeSupply: "",
      grinderRoller: "",
      recipeNote: "",
    };
  }

  extractNumber(str, label) {
    var matches = str.match(/(^[0-9]*\.?[0-9]*)/);
    console.log(label, ":", matches[0]);
    return matches[0];
  }

  onSubmitForm() {
    const ts = new Date();
    const payload = {
      body: {
        typeOfParams: "rule",
        ID: uuid(),
        cod: `${this.state.waffelDiameter.slice(
          0,
          2
        )}-${this.state.millingName
          .slice(0, 3)
          .toLowerCase()}-${this.state.waffelWeight.slice(
          0,
          3
        )}-${this.state.timeSupply.slice(
          0,
          2
        )}-${this.state.grinderRoller.slice(0, 2)}`,
        params: {
          millingName: this.state.millingName,
          waffelDiameter: this.extractNumber(
            this.state.waffelDiameter,
            "waffelDiameter"
          ),
          waffelWeight: this.extractNumber(
            this.state.waffelWeight,
            "waffelWeight"
          ),
          timeSupply: this.extractNumber(this.state.timeSupply, "timeSupply"),
          grinderRoller: this.state.grinderRoller,
          recipeNote: this.state.recipeNote || "_",
        },
        creationDate: ts.getTime(),
      },
    };
    this.extractNumber(this.state.waffelDiameter, "waffelDiameter");
    this.putItem(payload);

    this.setState({
      cod: "",
      millingName: "",
      waffelDiameter: "",
      waffelWeight: "",
      timeSupply: "",
      grinderRoller: "",
      recipeNote: "",
    });
    this.props.history.push("/impostazioni/parametriQualita");
  }

  async putItem(data) {
    API.post("apilucaffe", "/params", data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  render() {
    const cod = `${this.state.waffelDiameter.slice(
      0,
      2
    )}-${this.state.millingName
      .slice(0, 3)
      .toLowerCase()}-${this.state.waffelWeight.slice(
      0,
      3
    )}-${this.state.timeSupply.slice(0, 2)}-${this.state.grinderRoller.slice(
      0,
      2
    )}`;

    const {
      millingName,
      waffelDiameter,
      waffelWeight,
      timeSupply,
      recipeNote,
      grinderRoller,
    } = this.state;
    const disabled =
      millingName.length > 3 &&
      waffelDiameter.length > 1 &&
      waffelWeight.length > 0 &&
      timeSupply.length > 0 &&
      grinderRoller.length > 0;
    console.log(disabled);
    const optionDiam = data.waffelsDiam.map((item) => (
      <option key={item}>{item} mm</option>
    ));
    const optionWeight = data.waffelsWeight.map((item) => (
      <option key={item}>{item} gr.</option>
    ));
    const optionTime = data.supplyTime.map((item) => (
      <option key={item}>{item} sec.</option>
    ));

    return (
      <>
        <h1 className='text-center mb-4'>
          Nuovo parametro
          <Link
            className={"text-danger float-right"}
            to={{ pathname: "/impostazioni/parametriQualita" }}
          >
            <Icon icon={close} size={32} />
          </Link>
        </h1>
        <h5 className='my-2'>
          <span className='text-bold'>Codice: </span>
          {cod}
        </h5>
        <Form>
          <FormGroup>
            <Label for='recipeName'>Nome macinatura</Label>
            <Input
              type='text'
              name='recipeName'
              id='recipeName'
              placeholder='Nome della macinatura'
              value={millingName}
              onChange={(e) => this.setState({ millingName: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for='waffelDiameter'>Diametro cialda</Label>
            <input
              type='text'
              className='form-control'
              name='waffelDiameter'
              list='waffelDiameter'
              placeholder='Seleziona il diametro'
              value={waffelDiameter}
              onChange={(e) =>
                this.setState({ waffelDiameter: e.target.value })
              }
            ></input>
            <datalist id='waffelDiameter'>
              <option></option>
              {optionDiam}
            </datalist>
          </FormGroup>
          <FormGroup>
            <Label for='waffelWeight'>Peso</Label>
            <input
              type='text'
              className='form-control'
              list='waffelWeight'
              placeholder='Seleziona il peso'
              value={waffelWeight}
              onChange={(e) => this.setState({ waffelWeight: e.target.value })}
            ></input>
            <datalist id='waffelWeight'>
              <option></option>
              {optionWeight}
            </datalist>
          </FormGroup>
          <FormGroup>
            <Label for='waffelWeight'>Tempo di erogazione</Label>
            <input
              type='text'
              className='form-control'
              list='timeSupply'
              placeholder='Seleziona il tempo di erogazione'
              value={timeSupply}
              onChange={(e) => this.setState({ timeSupply: e.target.value })}
            ></input>
            <datalist id='timeSupply'>
              <option></option>
              {optionTime}
            </datalist>
          </FormGroup>
          <FormGroup>
            <Label for='grinderRoller'>Diametro rulli macinino inferiore</Label>
            <Input
              type='text'
              className='form-control'
              list='grinderRoller'
              placeholder='Seleziona il diametro'
              value={grinderRoller}
              onChange={(e) => this.setState({ grinderRoller: e.target.value })}
            />
            <datalist id='grinderRoller'>
              <option></option>
              <option>8</option>
              <option>12</option>
            </datalist>
          </FormGroup>
          <FormGroup>
            <Label for='recipeNote'>Note</Label>
            <Input
              name='recipeNote'
              type='textarea'
              value={recipeNote}
              onChange={(e) => this.setState({ recipeNote: e.target.value })}
            />
          </FormGroup>
          <Button
            outline
            color='primary'
            className='float-right m-4'
            onClick={() => {
              this.onSubmitForm();
            }}
            disabled={!disabled}
          >
            Salva
          </Button>
        </Form>
      </>
    );
  }
}
