import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import {AmplifySignOut} from '@aws-amplify/ui-react';



const Menu = () => {
  const  history = useHistory()
  const signOut =  async function () {
   try {
        await Auth.signOut();

    } catch (error) {
        console.log('error signing out: ', error);
    }
};
  const settingsMenu = [
    {
      name: "dashboard",
      link: "/",
    },
    {
      name: "storico",
      link: "/storico",
    },
    {
      name: "impostazioni",
      link: "/impostazioni",
    },
  ];
  const menuItem = settingsMenu.map((item) => (
    <NavLink
      key={item.name}
      to={item.link}
      className='nav-link'
      activeClassName='active'
      exact
    >
      {item.name}
    </NavLink>
  ));

  return (
    <div className='navbar-collapse justify-content-end'>
      <ul className='navbar-nav'>
        {menuItem}
        
          <AmplifySignOut buttonText="logout" ><NavLink className='nav-link' to='login' >logout</NavLink></AmplifySignOut>
        
      </ul>
    </div>
  );
};

export default Menu;
