import React, { Component } from "react";

import CardMachine from "../components/CardMachine";
import data from "../assets/data/machine";

class Dashboard extends Component {
  state = {
    machines: [],
    machineState: [],
  };

  componentDidMount() {
    this.setState({
      machines: data.machine,
    });
  }
  componentWillUnmount() {
    this.setState({ machines: [] });
  }
  render() {
    // TODO possibile estrarre il componente in un file separato
    // creazione delle card delle macchine presenti
    let machineList = this.state.machines.map((machine) => {
      // Prendo l'array di stati macchina e lo filtro in base alla seriale della macchina
      this.state.machineState.filter((item) => {
        return item.serialNumber === machine.serialNumber;
      });
      return (
        <CardMachine
          key={machine.ID}
          machineID={machine.ID}
          machineName={machine.name}
          machineDescription={machine.description}
          localization={machine.localization}
          yearOfMake={machine.yearOfMake}
          manufacturer={machine.manufacturer}
          serialNumber={machine.serialNumber}
          image={machine.image}
          fourDotZero={machine.fourDotZero}
          type={machine.type}
          machineState={"online"}
        />
      );
    });
    return (
      <div>
        {machineList.length !== 0 ? (
          machineList
        ) : (
          <h3>Nessuna macchina attualmente disponible</h3>
        )}
      </div>
    );
  }
}

export default Dashboard;
