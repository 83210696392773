import React from "react";

import { Card, CardTitle } from "reactstrap";
import { Pie } from "react-chartjs-2";

export default function ReducerCard({ title, label, labelMed, data }) {
  const pieData = {
    labels: ["Valide", "Errate"],
    datasets: [
      {
        data: [data.true, data.false],
        backgroundColor: ["#28a745", "#FF0000"],
      },
    ],
  };
  console.log(data);
  return (
    <div className='col-6'>
      <Card className='p-4'>
        <CardTitle>Controllo {title}</CardTitle>
        <div className='row'>
          <div className='col-6 px-0'>
            <h6 className='d-flex justify-content-between'>
              Numero prove valide:{" "}
              <span className='text-success font-weight-bold'>{data.true}</span>
            </h6>
            <h6 className='d-flex justify-content-between'>
              Numero prove errate:{" "}
              <span className='text-danger font-weight-bold'>{data.false}</span>
            </h6>
            <h6 className='d-flex justify-content-between'>
              Numero di prove totali:{" "}
              <span className='font-weight-bold'>{data.nr}</span>
            </h6>
            <h6 className='d-flex justify-content-between'>
              {labelMed}{" "}
              <span className='font-weight-bold'>
                {parseFloat(data.total / data.nr).toFixed(2)}
              </span>
            </h6>
          </div>
          <div className='col-6 px-0'>
            <Pie height={150} data={pieData} />
          </div>
        </div>
      </Card>
    </div>
  );
}
