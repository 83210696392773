import React from "react";
import { Row } from "reactstrap";

import { Icon } from "react-icons-kit";
import { meter } from "react-icons-kit/icomoon/meter";

export default function MachineState({ machineState }) {
  // Etichette degli stati macchina
  const label = {
    1: "Allarme",
    2: "Attesa prodotto",
    3: "In produzione",
    4: "In pausa",
    offline: "offline",
    online: "online",
    "4.0 off": "4.0 off",
  };
  return (
    <>
      <h5 className={"text-center mt-4"}>Stato macchina</h5>
      {/* se vi è un valore di stao lo stampo altrimeti stampo macchina on line */}
      {machineState.state ? (
        <>
          <Row className={"ml-2"}>
            <div
              className={`mt-1 machine-state machine-state-${machineState.state}`}
            ></div>
            <p className={"pl-3"}> {label[`${machineState.state}`]}</p>
          </Row>
          <p className={"text-center"} style={{ fontSize: "0.7em" }}>
            {machineState.timeToRead}{" "}
          </p>
        </>
      ) : (
        <p className={"text-center text-success"}>
          <Icon size={18} icon={meter} /> {label[machineState]}
        </p>
      )}
    </>
  );
}
