import { Translations } from "@aws-amplify/ui-components";

const it_lang = {
  [Translations.SIGN_IN_HEADER_TEXT]: "Effettua il Login",
  [Translations.SIGN_IN_ACTION]: "Invia",
  [Translations.USERNAME_LABEL]: "e-mail",
  [Translations.PASSWORD_LABEL]: "password",
  [Translations.USERNAME_PLACEHOLDER]: "Inserisci la tua e-mail",
  [Translations.PASSWORD_PLACEHOLDER]: "Inserisci la tua password",
  [Translations.FORGOT_PASSWORD_TEXT]: "Password dimenticata?",
  [Translations.RESET_YOUR_PASSWORD]: "Azzera la password",
};

export default it_lang;
