import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { CSVLink } from "react-csv";

import { SingleDatePicker } from "react-dates";
import { FormGroup, Label, Input, Button, Spinner } from "reactstrap";
import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";
import { printer } from "react-icons-kit/ikons/printer";
import { document_download } from "react-icons-kit/ikons/document_download";

import { utility } from "../utility/utilities";
import data from "../assets/data/machine";
import QualityTable from "../components/QualityTable";

export default class StoricoQual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: [],
      selectMachine: null,
      nameSelectMachine: null,
      date: null,
      tableDate: null,
      selectedDate: null,
      focused: false,
      spinner: false,
      print: false,
      dailyQuality: [],
      noData: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ machines: data.machine });
  }
  UNSAFE_componentWillUnmount() {
    this.setState({
      machines: [],
      selectMachine: null,
      nameSelectMachine: null,
      dailyQuality: [],
    });
  }

  handleChange(e) {
    const selected = this.state.machines.filter((machine) => {
      return machine.name === e.target.value;
    });
    console.log(selected[0].serialNumber);
    this.setState({
      selectMachine: selected[0].serialNumber,
      nameSelectMachine: selected[0].name,
      dailyProduction: {},
    });
  }

  async callApi() {
    this.setState({ noData: false, tableDate: this.state.date });
    const machineID = this.state.machines.filter((item) => {
      if (item.serialNumber === this.state.selectMachine) return item;
      return null;
    })[0].ID;
    try {
      // Effettuo query filtrata per giorno
      const filterDate = moment(this.state.date).format("YYYY-MM-DD");
      const payload = {
        queryStringParameters: {
          machineId: machineID,
          ts_machine: filterDate,
        },
      };
      const qualityData = await API.get(
        "apilucaffe",
        `/qualitydata/${machineID}`,
        payload
      );
      // filtro i valori di tempo inferiori ad 1 secodo giudicati non conformi
      const dayQualityData = qualityData.filter((item) => {
        const time = item.proof[1] ? item.proof[1].val : 2;
        if (time && time > 1) return item;
        return "";
      });
      dayQualityData.length > 0
        ? this.setState({
            dailyQuality: dayQualityData,
            spinner: false,
            print: true,
            noData: false,
          })
        : this.setState({ noData: true });
      this.setState({ spinner: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    let date = this.state.date ? this.state.date.format("DD-MM-YYYY") : null;
    if (this.state.selectMachine && date) {
      console.log(this.state.selectMachine, date);
    }
    const tableConstr = {
      type: "quality",
      title: `Data di produzione: ${moment(this.state.tableDate).format(
        "DD-MM-YYYY"
      )}`,
      thead: [
        "Ora Prova",
        "operatore",
        "Cod. Param.",
        "macinatura",
        "diam. cialda",
        "diam. macinino",
        "peso reg.",
        "tempo erogazione reg.",
      ],
      action: "",
      visible: "d-none",
    };
    const option = this.state.machines.map((machine) => {
      if(!machine.qualityControl){ return }
      return <option key={machine.serialNumber}>{machine.name}</option>
    });

    const noData = (
      <h4 className='text-center mt-5'>
        Nella giornata non vi sono prove disponibili
      </h4>
    );
    return (
      <>
        {/* ref previsto refactoring aggiungendo componente card */}
        <div className='no-print mb-3'>
          <h1 className='text-center mb-4'>
            Storico Controlli Qualità
            <div className='float-right'>
              {this.state.print && (
                <>
                  <CSVLink
                    data={utility.csvExportFormatting(
                      tableConstr.thead,
                      this.state.dailyQuality
                    )}
                    filename={"qualityTest.csv"}
                  >
                    <Icon icon={document_download} size={32} />
                  </CSVLink>
                  <button
                    type='button'
                    class='btn btn-light'
                    onClick={() => this.state.print && window.print()}
                  >
                    <Icon icon={printer} size={32} />
                  </button>
                </>
              )}

              <Link className={"text-danger "} to={{ pathname: "/storico" }}>
                <Icon icon={close} size={32} />
              </Link>
            </div>
          </h1>
          <h6 className='py-4'>
            In questa pagina puoi consultare lo storico dei controlli qualità
            giornalieri per macchina
          </h6>
          <FormGroup className='d-flex flex-row w-50 justify-content-between'>
            <Label for='exampleSelect'>Seleziona una macchina :</Label>
            <Input
              type='select'
              name='select'
              id='selectMachine'
              className='ml-3 w-50'
              onChange={this.handleChange}
            >
              <option></option>
              {option}
            </Input>
          </FormGroup>
          <FormGroup className='d-flex flex-row align-items-center w-50 justify-content-between'>
            <p className='mb-0 mr-3'>Seleziona una data :</p>
            <SingleDatePicker
              showClearDate
              reopenPickerOnClearDate
              isOutsideRange={(date) => date > new Date()}
              placeholder='Data'
              displayFormat={"DD-MM-YYYY"}
              id='date'
              small
              numberOfMonths={1}
              monthFormat={"MMMM YYYY"}
              date={this.state.date}
              onDateChange={(date) => {
                this.setState({ date });
              }}
              focused={this.state.focused}
              onFocusChange={({ focused }) => {
                this.setState({ focused });
              }}
            />
          </FormGroup>

          <Button
            onClick={() => {
              this.callApi();
              this.setState({ date: null, spinner: true, dailyQuality: [] });
            }}
          >
            Invia Richiesta
          </Button>
        </div>
        <div className='only-print mt-5'>
          <h1 className='text-center mb-1 mt-5'>Controllo Qualità</h1>
          <h3 className='text-center mb-4'>
            Produzione: {this.state.nameSelectMachine}
          </h3>
          <div className='w-100 d-flex justify-content-center mt-5'></div>
        </div>
        {this.state.spinner && <Spinner type='grow' color='warning' />}
        {this.state.noData ? noData : null}
        {this.state.dailyQuality.length > 0 ? (
          <QualityTable
            constructor={tableConstr}
            data={this.state.dailyQuality}
          />
        ) : null}
      </>
    );
  }
}
