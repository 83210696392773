import React, { useState } from "react";
import moment from "moment";
import { Table, Card, CardTitle, Button, Spinner, Fade } from "reactstrap";
import { Link } from "react-router-dom";

import DailyQualityReducer from "./DailyQualityReducer";

const QualityTable = ({ constructor, data }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const toggle = () => setFadeIn(!fadeIn);
  let tableBody = [];
  if (constructor.type === "quality") {
    data === "no data"
      ? (tableBody = "null")
      : (tableBody = data.map((item, i) => {
          const timeToTest = item.ts_created.split(".")[0];
          return (
            <tr key={i}>
              <td className={"text-right"}>
                {moment(timeToTest).format("LT")}
              </td>
              <td>{item.user.cod}</td>
              <td>{item.rules_code}</td>
              <td>{item.rules.millingName}</td>
              <td className={"text-center"}>{item.rules.waffelDiameter}</td>
              <td className={"text-center"}>{item.rules.grinderRoller}</td>
              <td
                className={
                  item.proof[0] && item.proof[0].esito === true
                    ? `text-center text-success`
                    : `text-center text-danger`
                }
              >
                {item.proof.length > 0 ? item.proof[0].val : "n.d."}
              </td>
              {item.proof[1] ? (
                <td
                  className={
                    item.proof[1].esito === true
                      ? `text-center text-success`
                      : `text-center text-danger`
                  }
                >
                  {item.proof.length > 0
                    ? `${item.proof[1].val.slice(0, 5)} Sec.`
                    : "n.d."}
                </td>
              ) : (
                <td> </td>
              )}
            </tr>
          );
        }));
  } else {
    return null;
  }

  const table = constructor.thead.map((item) => (
    <th key={item} className='text-capitalize'>
      {item}
    </th>
  ));
  return (
    <>
      <Card className='p-4 mb-1'>
        <CardTitle>
          <h5>{constructor.title}</h5>
        </CardTitle>
        {tableBody.length > 0 ? (
          tableBody !== "null" ? (
            <>
              {constructor.type === "quality" && (
                <DailyQualityReducer data={data} />
              )}
              <div className='mb-1 d-flex justify-content-end'>
                <Button
                  className='m-2 '
                  outline
                  size='sm'
                  color={!fadeIn ? "secondary" : "success"}
                  onClick={toggle}
                >
                  {!fadeIn ? "Dettaglio Off" : "Dettaglio On"}
                </Button>
              </div>
              <Fade in={fadeIn}>
                <Table>
                  <thead>
                    <tr>{table}</tr>
                  </thead>
                  <tbody>{tableBody}</tbody>
                </Table>
                <div className='border-top d-flex justify-content-end'>
                  <p className='pt-2 pr-3'>
                    Prove effettuate nella giornata: {data.length}
                  </p>
                </div>
              </Fade>
            </>
          ) : (
            <p className='text-center'>
              Nessuna prova di qualità nella giornata
            </p>
          )
        ) : (
          // TODO: da gestire il caso in cui non ci sono dati
          <div className='w-100 d-flex justify-content-center'>
            <Spinner size='lg' color='primary my-3' />
          </div>
        )}
      </Card>
      <Link to={{ pathname: `/impostazioni/${constructor.action}` }}>
        <Button
          outline
          color='primary'
          className={`float-right m-4 ${constructor.visible}`}
        >
          Aggiungi
        </Button>
      </Link>
    </>
  );
};

export default QualityTable;
