import { API } from "aws-amplify";
import moment from "moment";
import { utility } from "../utility/utilities";

/**
 * Funzione per estrarre i quantitativi prodotti in una determinata
 * giornata, la richiesta viene ciclata sulle ore della giornata dalle 7 alle 19
 * per i gironi di storico, per la lettura odierna il ciclo termina all'ora corrente
 * di invio richiesta.
 * La funzione restituisce un array di oggetti contatore.
 * @param {string} machineId
 * @param {string} date
 * @param {function} callback
 */

export const readDailyHourProduct = function (
  machineType,
  machineId,
  date,
  callback
) {
  let hourProd = [];
  let today = new Date();
  let currentHour =
    date === moment(today).format("YYYY-MM-DD") ? today.getHours() : 19;
  for (let h = 7; h <= currentHour; h++) {
    const dhour = h.toString().length === 2 ? h : `0${h}`;
    const payload = {
      queryStringParameters: {
        machineId: machineId,
        ts_machine: `${date} ${dhour}:`,
      },
    };
    // AlbaTODO da togliere appena andrea Fixa
    if (machineType === "FSH") {
      payload.queryStringParameters.ts_machine = `${date}T${dhour}:`;
    }

    // console.log(payload);
    API.post("apiGetProdData", "/production", payload)
      .then((res) => {
        const leng = res.length;
        if (leng && res !== '{"response":"no production in this day"}') {
          hourProd.push({
            key: h,
            ora: `${h}-${h + 1}`,
            quantità: utility.productionParse(machineType, res, leng),
          });
        } else {
          hourProd.push({
            key: h,
            ora: `${h}-${h + 1}`,
            quantità: 0,
          });
        }
        hourProd.sort((a, b) => a.key - b.key);
        callback(hourProd);
      })
      .catch((err) => console.log(err));
  }
};
