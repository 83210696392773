import React from "react";
import { Pie } from "react-chartjs-2";

import { Card,  CardBody, CardTitle} from 'reactstrap';

const moment = require("moment");

const MontlyState = (props) => {
        const data = props.monthlyData;
        const dataArray = data.map(item =>{
            const sum = item.alarms + item.wait + item.run + item.idle;
            return({
                month: item.date,
                data: {
                labels: ["Alarms", "Wait", "Run", "Idle"],
                    datasets: [{
                    data: [Math.floor(((item.alarms*100)/sum)*100)/100, Math.floor(((item.wait*100)/sum)*100)/100, Math.floor(((item.run*100)/sum)*100)/100, Math.floor(((item.idle*100)/sum)*100)/100],
                    backgroundColor: [
                        '#D21919',
                        '#ffc107',
                        '#12d615',
                        '#535F6B'
                    ]
                    }]  
                }})
        })

    
    const charts = dataArray.map(element => {
         return (
            <Card key={element.month} className={"col-4"}>
                <CardTitle><h6>{element.month}</h6></CardTitle>
                <Pie
                height= {100}
                data= {element.data}
                />
            </Card>
        )
    });

    return (
        <Card className={"mt-5 mb-5"}>
            <CardTitle><h5 className={"pl-3 pt-1"}>{props.monthlyTitleDescription}{moment().format("YYYY")}, al {moment().format("D-MM")}</h5></CardTitle>
            <CardBody className={"row"}>
                {charts}
            </CardBody>
        </Card>
    )
}

export default MontlyState;

