import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";

import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";
import TableData from "../../components/TableData";
import CardContainerHoc from "../../hoc/card-container-hoc";

const tableConstr = {
  title: "Lista operatori",
  footer: "Numero operatori",
  thead: ["matricola", "nome", "cognome", "mansione", "azioni"],
  action: "nuovo/operatore",
};

const Operatori = () => {
  const [operators, setOperators] = useState([]);

  async function callApi() {
    try {
      const operatorData = await API.get("apilucaffe", `/params/operator`);
      const filtredData = operatorData.filter((item) =>
        item.flag !== "del" ? item : null
      );
      setOperators(filtredData);
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteOperator(ID) {
    try {
      await API.patch("apilucaffe", `/params/flag/operator/${ID}`);
      callApi();
    } catch (err) {
      console.log(err);
    }
  }
  function handleDelete(item) {
    deleteOperator(item);
  }

  useEffect(() => {
    callApi();
  }, []);

  return (
    <CardContainerHoc title='Operatori' noBorder>
      <TableData
        constructor={tableConstr}
        data={operators}
        handleDelete={handleDelete}
      />
    </CardContainerHoc>
  );
};
export default Operatori;
