export const utility = {
  insInDailyArray(data) {
    const dailyArray = Array(24).fill(0);
    if (Array.isArray(data)) {
      data.map((item) => (dailyArray[item.key] = item.quantità));
      return dailyArray;
    } else {
      console.log("data in not valid Array");
    }
  },
  /**
   *
   * @param {array} data
   * @param {string} reducerSubject
   * funzione che raggruppa le prove di qualità per soggetto
   * le opzioni utilizzabili sono: rules o user
   * restituisce un oggetto contenente prove raggruppate in un array che
   * ha per nome il millingName della rules utilizzata
   * oppure un oggetto che contiene le prove raggruppate per codice utente
   */

  qualityReducer(data, reducerSubject) {
    const uniqueMilling = {};

    if (!Array.isArray(data)) {
      console.log("err: accept array");
      return;
    }
    console.log("init reducer func");

    data.forEach((item) => {
      const subject = {
        user: item.user.cod,
        milling: item.rules.millingName,
      };
      const args = subject[reducerSubject];
      if (uniqueMilling[args]) {
        uniqueMilling[args].push(item);
      } else {
        uniqueMilling[args] = [item];
      }
    });
    console.log(uniqueMilling);
    return uniqueMilling;
  },
  /**
   *
   * @param {array} data
   * funzione che raggruppa i risultati delle
   * prove effettuate restituendo
   * uno score di prove true un totale dei valori rilevati
   * ed un numero di prove eseguite per ogni tipo di prova
   */
  qualityScore(data) {
    const qresult = {
      cron: { true: 0, false: 0, total: 0, nr: 0 },
      balance: { true: 0, false: 0, total: 0, nr: 0 },
    };
    data.forEach((element) => {
      element.proof.forEach((item) => {
        qresult[item.type].nr += 1;
        if (item.esito) {
          qresult[item.type].true += 1;
          qresult[item.type].total += parseFloat(item.val);
        } else {
          qresult[item.type].false += 1;
          qresult[item.type].total += parseFloat(item.val);
        }
      });
    });
    console.log(qresult);
    return qresult;
  },

  /**
   *
   * @param {array} data
   * funzione che dato l'array di prove ritorna
   * un array di score divisi per nome della regola (ricetta)
   */
  qualityScoreForMilling(data) {
    const score = [];
    const proof = this.qualityReducer(data, "milling");
    for (let rules in proof) {
      const result = this.qualityScore(proof[rules]);
      score.push({ millingName: rules, score: result });
    }
    return score;
  },
  csvExportFormatting(label, data) {
    const arrayResult = [label];
    data.map((item) => {
      const timeToTest = item.ts_created.split(".")[0];
      let row = [
        timeToTest,
        item.user.cod,
        item.rules_code,
        item.rules.millingName,
        item.rules.waffelDiameter,
        item.rules.grinderRoller,
        item.proof[0] && item.proof[0].val,
        item.proof[1] && item.proof[1].val,
      ];
      arrayResult.push(row);
    });

    console.log(arrayResult);
    return arrayResult;
  },
  /**
   *
   * @param {string} machineType
   * @param {array} res
   * @param {int} leng
   * @returns {int}
   * @description funzione che parsa le risposte di produzione fornendo il dato numerico
   */
  productionParse(machineType, res, leng) {
    switch (machineType) {
      case "FSH":
        return res[leng - 1].pack.counter.delta["L104_15"];
      case "I590":
        return res[leng - 1].pack.DoubleIntegerData_3_2.delta;
      case "CO1037":
        return res[leng - 1].pack[`::237_LottoP:pezziProdottiLottoAttuale_6`]
          .delta;
      case "GOGL":
        console.log("prod Goglio");
        if (Array.isArray(res)) {
          let prod = res[leng - 1]?.pack["VIS_CONTAT_SACCH_FF1"];
          return prod.delta - prod.ref;
        }
        return 0;
      default:
        return res[leng - 1].pack.state_good_products;
    }
  },
  /** Funzione di validazione della form di salvataggio lotto */
  validateForm(errors, type) {
    // se non è una CA6 setto due campi che non vi sono
    if (type !== "CA6") {
      errors.batchWaffleSize = "true";
      errors.batchEnvelopesCarton = "true";
    }
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => {
        val !== "true" && (valid = false);
      }
    );

    return valid;
  },
};
