import React from "react";
import moment from "moment";
import { Table } from "reactstrap";
import { Icon } from "react-icons-kit";
import { cross } from "react-icons-kit/icomoon/cross";
import { checkmark } from "react-icons-kit/icomoon/checkmark";

export const PlanningTable = ({ planning, handleDeleteBatch }) => {
  const rowData = planning.map((row, i) => (
    <tr key={i}>
      <th className='font-weight-light'>
        <div className='d-flex flex-column'>
          {i + 1}
          {row.response ? (
            row.response.status ? (
              <Icon
                className='text-success'
                icon={checkmark}
                title='Pianificazione inviata correttamente'
              />
            ) : (
              " "
            )
          ) : (
            " "
          )}
        </div>
      </th>
      <th className='font-weight-light'>{row.params.bacthHumanName}</th>
      <th className='font-weight-light'>{row.params.batchDescription}</th>
      <th className='font-weight-light'>{row.params.blend}</th>
      <th className='font-weight-light'>{row.params.batchWaffleSize}</th>
      <th className='font-weight-light'>
        <p className='mb-0'>
          prod:{" "}
          {typeof row.batchTimeToProduction == "string"
            ? moment(row.batchTimeToProduction).format("DD-MM-YYYY HH:mm")
            : "n.d."}
        </p>
        <p className='mb-0'>
          scad:{" "}
          {typeof row.tsDelete == "string"
            ? moment(row.tsDelete, "X").format("DD-MM-YYYY")
            : "n.d."}
        </p>
        <p className='mb-0'>
          invio in macchina:{" "}
          {typeof row.batchTimeToInsert == "string"
            ? moment(row.batchTimeToInsert).format("DD-MM-YYYY HH:mm")
            : "n.d."}
        </p>
      </th>
      <th>
        <Icon
          className='text-danger'
          style={{ cursor: "pointer" }}
          icon={cross}
          onClick={(e) => {
            alert(`are you sure cancel field ${row.params.batchDescription} ?`);
            return handleDeleteBatch({
              typeOfParams: row.typeOfParams,
              batchTimeToProduction: row.batchTimeToProduction,
              ID: row.ID,
            });
          }}
        />
      </th>
    </tr>
  ));

  return (
    <>
      <h4>Ultimi Lotti pianificati</h4>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome Lotto</th>
            <th>Descrizione</th>
            <th>Miscela</th>
            <th>Diametro</th>
            <th>Date</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>{rowData.reverse()}</tbody>
      </Table>
    </>
  );
};
