import React, { Component } from "react";
import uuid from "uuid";

import { API, Auth } from "aws-amplify";

import { SingleDatePicker } from "react-dates";
import { PlanningTable } from "../components/PlanningTable";
import { utility } from "../utility/utilities";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Modale from "../components/Modale";
import SelectOptions from "../components/SelectOptions";
import ToastMessage from "../components/ToastMessage";
import CardContainerHoc from "../hoc/card-container-hoc";

import { caffeVariants, gogl, time } from "../utility/variants";
import { planLabel } from "../utility/dict";

import moment from "moment";

const errors = {
  batchDescription: "",
  batchCartons: "",
  batchEnvelopesCarton: "",
  batchWaffleSize: "",
  blend: "",
  hour: "",
  minute: "",
};

export default class ProductionPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        typeOfParams: `batch-${this.props.location.state.machine.serialNumber}`,
        ID: uuid(),
        batchTimeToProduction: null,
        bacthHumanName: "",
        batchDescription: "",
        batchCartons: "",
        batchEnvelopesCarton: "",
        batchWaffleSize: "",
        blend: "",
        tsDelete: "",
        creationDate: "",
        user: "",
      },
      hour: "",
      minute: "",
      disabled: true,
      planning: null,
      isModalOpen: false,
      modalTitle: "",
      modalMessage: "",
      buttonDisabled: false,
      messageTitle: "",
      messageBody: "",
      messageBkg: "",
      spinnerBtnSave: false,
      spinnerBtnPlan: false,
      machineType: this.props.location.state.machine.type,
      optionalVal:
        planLabel[this.props.location.state.machine.type].optionalValidation,
      errors,
    };
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.handlebacthHumanName = this.handlebacthHumanName.bind(this);
    this.handlePreviusPlanning = this.handlePreviusPlanning.bind(this);
    this.handleDeleteBatch = this.handleDeleteBatch.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      this.setState({
        payload: { ...this.state.payload, user: user.attributes.email },
      });
    });
  }

  handleError = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let { errors } = this.state;

    switch (name) {
      case "batchDescription":
        errors.batchDescription =
          value.length < 4
            ? "Il nome deve essere almeno di 4 caretteri"
            : "true";
        break;
      case "blend":
        errors.blend =
          value.length > 3 ? "true" : "Il campo miscela non deve essere vuoto";
        break;
      case "batchCartons":
        errors.batchCartons =
          value.length < 1 ? "Inserire la quantità" : "true";
        break;
      case "batchEnvelopesCarton":
        errors.batchEnvelopesCarton =
          value.length < 1 ? "Inserire la quantità" : "true";
        break;
      case "batchWaffleSize":
        errors.batchWaffleSize =
          value.length < 1 ? "Inserire la dimensione" : "true";
        break;
      case "hourToStart":
        errors.hour = value.length < 0 ? "Inserire l'ora" : "true";
        break;
      case "minuteToStart":
        errors.minute = value.length < 1 ? "Inserire i minuti" : "true";
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  handlebacthHumanName(e) {
    const newFormatDeadLine = moment(
      this.state.payload.batchTimeDeadline
    ).format("DD/MM/YYYY");
    const bacthHumanName = `${this.props.location.state.machine.serialNumber}-${e.target.value}-${newFormatDeadLine}`;
    this.setState({
      payload: {
        ...this.state.payload,
        blend: e.target.value,
        bacthHumanName,
      },
    });
  }
  setDayToStart(d) {
    const today = moment().format("YYYY-MM-DD");
    const availableDates = [today];
    return availableDates.some((date) => d.isSame(date, "day"));
  }

  async handlePreviusPlanning() {
    this.setState({ spinnerBtnPlan: true });
    const machineSerial = this.props.location.state.machine.serialNumber;
    try {
      const batch = await API.get(
        "apilucaffe",
        `/params/batch-${machineSerial}`
      );
      this.setState({ planning: batch, spinnerBtnPlan: false });
    } catch (err) {
      console.log("ERRORE");
      console.log(err);
    }
  }

  async onSubmitForm(e) {
    e.preventDefault();
    if (utility.validateForm(this.state.errors, this.state.machineType)) {
      let paramsPayload = {};
      const { machineType, payload } = this.state;
      console.log(machineType);

      if (machineType.includes("CA6")) {
        paramsPayload = {
          bacthHumanName: payload.bacthHumanName,
          batchCartons: payload.batchCartons,
          batchDescription: payload.batchDescription,
          batchEnvelopesCarton: payload.batchEnvelopesCarton,
          batchName: payload.ID,
          batchWaffleSize: payload.batchWaffleSize,
          blend: payload.blend,
          creationDate: moment(
            payload.batchTimeToInsert,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm"),
        };
      } else if (machineType === "CO1037" || machineType === "FSH") {
        paramsPayload = {
          bacthHumanName: payload.bacthHumanName,
          batchJar: payload.batchCartons,
          batchDescription: payload.batchDescription,
          batchName: payload.ID,
          blend: payload.blend,
          creationDate: moment(
            payload.batchTimeToInsert,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm"),
        };
      } else if (machineType.includes("I590")) {
        paramsPayload = {
          bacthHumanName: payload.bacthHumanName,
          batchPcs: payload.batchCartons,
          batchDescription: payload.batchDescription,
          batchName: payload.ID,
          blend: payload.blend,
          creationDate: moment(
            payload.batchTimeToInsert,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm"),
        };
      } else if (machineType.includes("GOGL")) {
        paramsPayload = {
          bacthHumanName: payload.bacthHumanName,
          batchJar: payload.batchCartons,
          batchDescription: payload.batchDescription,
          batchName: payload.ID,
          batchProgram: payload.blend,
          ruleType: "lotto",
          creationDate: moment(
            payload.batchTimeToInsert,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm"),
        };
      }
      const errorForm = [];
      for (let param in this.state.errors) {
        if (!this.state.errors[param].length) {
          errorForm.push(param);
        }
      }
      if (errorForm.length > 0) {
        console.log(errorForm);
        return null;
      }

      this.setState({ spinnerBtnSave: true });
      let completePayload = {
        typeOfParams: this.state.payload.typeOfParams,
        ID: this.state.payload.ID,
        batchTimeToProduction: moment(
          this.state.payload.batchTimeToProduction,
          "YYYY-MM-DD HH:mm"
        ).format("YYYY-MM-DD HH:mm"),
        tsDelete: moment(
          this.state.payload.batchTimeDeadline,
          "YYYY-MM-DD"
        ).format("X"),
        user: this.state.payload.user,
        params: paramsPayload,
      };
      // console.log("payload : ");
      console.log(JSON.stringify(completePayload, null, 2));

      let params = {
        body: completePayload,
      };
      try {
        const data = await API.post("apilucaffe", "/batch", params);
        if (data.success) {
          this.setState({
            messageBkg: "success",
            messageTitle: "Success",
            messageBody: "Pianificazione lotto aggiunta",
            spinnerBtnSave: false,
          });
        }
      } catch (err) {
        this.setState({
          messageBkg: "danger",
          messageTitle: "Error",
          messageBody: "Pianificazione non salvata",
        });
        console.log(err);
      }

      setTimeout(() => {
        this.setState({ messageBkg: "", messageTitle: "", messageBody: "" });
      }, 2000);

      this.setState({
        payload: {
          ...this.state.payload,
          batchName: "",
          bacthHumanName: "",
          batchDescription: "",
          batchCartons: "",
          batchEnvelopesCarton: "",
          batchWaffleSize: "",
          blend: "",
          batchTimeToProduction: null,
          batchTimeDeadline: null,
          batchTimeToInsert: null,
        },
        hour: "",
        minute: "",
        disabled: true,
        focused: false,
        planning: null,
        buttonDisabled: true,
        errors,
      });
    } else {
      this.setState({
        messageBkg: "danger",
        messageTitle: "Error",
        messageBody: "Completare tutti i campi",
      });
      setTimeout(() => {
        this.setState({ messageBkg: "", messageTitle: "", messageBody: "" });
      }, 2700);
    }
  }

  async handleDeleteBatch(data) {
    const { typeOfParams, ID } = data;
    try {
      const toDelete = await API.del(
        "apilucaffe",
        `/batch/object/${typeOfParams}/${ID}`
      );
      window.scrollTo({ top: 0 });
      if (toDelete) {
        this.setState({
          messageBkg: "success",
          messageTitle: "Success",
          messageBody: "Pianificazione lotto aggiunta",
        });
      }
    } catch (err) {
      this.setState({
        messageBkg: "danger",
        messageTitle: "Pianificazione non salvata",
        messageBody: err.message,
      });
    }
    setTimeout(() => {
      this.setState({ messageBkg: "", messageTitle: "", messageBody: "" });
    }, 2000);
    this.handlePreviusPlanning();
  }

  render() {
    let mahineSerialNumber = this.props.location.state.machine.serialNumber;
    const { errors } = this.state;
    return (
      <CardContainerHoc title={this.state.messageTitle}>
        {this.state.messageTitle && (
          <ToastMessage
            backgroud={this.state.messageBkg}
            title={this.state.messageTitle}
            body={this.state.messageBody}
          />
        )}
        <Row>
          <h1>Pianificazione produzione - {mahineSerialNumber}</h1>
        </Row>
        <h6>
          Nome del lotto:{" "}
          {this.state.payload.bacthHumanName
            ? this.state.payload.bacthHumanName
            : mahineSerialNumber}
        </h6>
        <Form className='mb-5'>
          <Row form>
            <Col lg={8}>
              <FormGroup>
                <Label for='batchDescription'>
                  {planLabel[this.state.machineType].batchDescription.label}
                </Label>
                <Input
                  type='textarea'
                  placeholder={
                    planLabel[this.state.machineType].batchDescription
                      .placeholder
                  }
                  id='batchDescription'
                  name='batchDescription'
                  onChange={(e) => {
                    this.handleError(e);
                    this.setState({
                      payload: {
                        ...this.state.payload,
                        batchDescription: e.target.value,
                      },
                    });
                  }}
                  value={this.state.payload.batchDescription}
                />

                {errors.batchDescription.length > 4 && (
                  <span className='error'>{errors.batchDescription}</span>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <h6 className='text-center'>Inizio produzione</h6>
              <Row form className='justify-content-around'>
                <div className='d-flex flex-column'>
                  <Label for='exampleText'>Data</Label>
                  <SingleDatePicker
                    showClearDate
                    reopenPickerOnClearDate
                    isDayBlocked={(day) => this.setDayToStart(day)}
                    required
                    placeholder='Data'
                    displayFormat={"DD-MM-YYYY"}
                    id='date'
                    small
                    numberOfMonths={1}
                    monthFormat={"MMMM YYYY"}
                    date={this.state.payload.batchTimeToProduction}
                    onDateChange={(date) => {
                      this.setState({
                        payload: {
                          ...this.state.payload,
                          batchTimeToProduction: date,
                        },
                        disabled: false,
                      });
                    }}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => {
                      this.setState({ focused });
                    }}
                  />
                </div>
                <FormGroup>
                  <Label for='hour'>ora</Label>
                  <Input
                    type='select'
                    name='hourToStart'
                    id='hourToStart'
                    disabled={this.state.disabled}
                    onChange={(e) => {
                      this.handleError(e);
                      // controllo se è stata scritta correttamente la data nel datepiker
                      if (this.state.payload.batchTimeToProduction) {
                        this.setState({
                          hour: e.target.value,
                          payload: {
                            ...this.state.payload,
                            batchTimeToProduction:
                              this.state.payload.batchTimeToProduction.hours(
                                e.target.value
                              ),
                          },
                        });
                        return;
                      }
                      this.setState({
                        isModalOpen: !this.state.isModalOpen,
                        modalTitle: "ATTENZIONE",
                        modalMessage: "La data inserita non è valida",
                      });
                    }}
                    value={this.state.hour}
                  >
                    <SelectOptions optArr={time.hour} />
                  </Input>
                  {errors.hour.length > 4 && (
                    <span className='error'>{errors.hour}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for='minuteToStart'>minuti</Label>
                  <Input
                    type='select'
                    name='minuteToStart'
                    id='minuteToStart'
                    disabled={this.state.disabled}
                    onChange={(e) => {
                      this.handleError(e);
                      // clono la data così da rendere immutabile batchTimeToProduction
                      const cloneDate =
                        this.state.payload.batchTimeToProduction.clone();
                      this.setState({
                        minute: e.target.value,
                        payload: {
                          ...this.state.payload,
                          batchTimeToProduction:
                            this.state.payload.batchTimeToProduction.minute(
                              e.target.value
                            ),
                          batchTimeDeadline: cloneDate
                            .add(1, "year")
                            .format("YYYY-MM-DD"),
                          batchTimeToInsert: moment(new Date()).format(
                            "YYYY-MM-DD HH:mm"
                          ),
                        },
                      });
                    }}
                    value={this.state.minute}
                  >
                    <SelectOptions optArr={time.minute} />
                  </Input>
                  {errors.minute.length > 4 && (
                    <span className='error'>{errors.minute}</span>
                  )}
                </FormGroup>
              </Row>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for='blend'>
                  {planLabel[this.state.machineType].blend?.label ||
                    "Tipo di miscela"}
                </Label>
                <Input
                  type='select'
                  name='blend'
                  id='blend'
                  value={this.state.payload.blend}
                  onChange={(e) => {
                    this.handleError(e);
                    this.handlebacthHumanName(e);
                    console.log(gogl.blends.indexOf(e.target.value));
                  }}
                >
                  {this.state.machineType === "GOGL" ? (
                    <SelectOptions optArr={gogl.blends} />
                  ) : (
                    <SelectOptions optArr={caffeVariants.blends} />
                  )}
                </Input>
                {errors.blend.length > 4 && (
                  <span className='error'>{errors.blend}</span>
                )}
              </FormGroup>
            </Col>
            {planLabel[this.state.machineType].batchWaffleSize.visible && (
              <Col md={6}>
                <FormGroup>
                  <Label for='exampleSelectMulti'>
                    {planLabel[this.state.machineType].batchWaffleSize.label}
                  </Label>
                  <Input
                    type='select'
                    name='batchWaffleSize'
                    id='batchWaffleSize'
                    value={this.state.payload.batchWaffleSize}
                    onChange={(e) => {
                      this.handleError(e);
                      this.setState({
                        payload: {
                          ...this.state.payload,
                          batchWaffleSize: e.target.value,
                        },
                      });
                    }}
                  >
                    <SelectOptions optArr={caffeVariants.waffelsSize} />
                  </Input>
                  {errors.batchWaffleSize.length > 4 && (
                    <span className='error'>{errors.batchWaffleSize}</span>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for='exampleText'>
                  {planLabel[this.state.machineType].batchCartons.label}
                </Label>
                <Input
                  type='text'
                  placeholder={
                    planLabel[this.state.machineType].batchCartons.placeholder
                  }
                  id='batchCartons'
                  name='batchCartons'
                  value={this.state.payload.batchCartons}
                  onChange={(e) => {
                    this.handleError(e);
                    this.setState({
                      payload: {
                        ...this.state.payload,
                        batchCartons: e.target.value,
                      },
                    });
                  }}
                />
                {errors.batchCartons.length > 4 && (
                  <span className='error'>{errors.batchCartons}</span>
                )}
              </FormGroup>
            </Col>
            {planLabel[this.state.machineType].batchEnvelopesCarton.visible && (
              <Col md={6}>
                <FormGroup>
                  <Label for='batchEnvelopesCarton'>
                    {
                      planLabel[this.state.machineType].batchEnvelopesCarton
                        .label
                    }
                  </Label>
                  <Input
                    type='number'
                    placeholder={
                      planLabel[this.state.machineType].batchEnvelopesCarton
                        .placeholder
                    }
                    id='batchEnvelopesCarton'
                    name='batchEnvelopesCarton'
                    value={this.state.payload.batchEnvelopesCarton}
                    onChange={(e) => {
                      this.handleError(e);
                      this.setState({
                        payload: {
                          ...this.state.payload,
                          batchEnvelopesCarton: e.target.value,
                        },
                      });
                    }}
                  />
                  {errors.batchEnvelopesCarton.length > 4 && (
                    <span className='error'>{errors.batchEnvelopesCarton}</span>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row className='justify-content-end'>
            <Button
              disabled={this.state.buttonDisabled}
              onClick={this.onSubmitForm}
              outline
              className='my-2 px-4'
            >
              {this.state.spinnerBtnSave ? (
                <Spinner size='sm' color='primary' />
              ) : (
                "Salva"
              )}
            </Button>
          </Row>
        </Form>
        <Modale
          title={this.state.modalTitle}
          message={this.state.modalMessage}
          isModalOpen={this.state.isModalOpen}
          toggle={(e) =>
            this.setState({
              isModalOpen: !this.state.isModalOpen,
              disabled: true,
              modalMessage: "",
              modalTitle: "",
            })
          }
        />
        <div>
          {this.state.planning ? (
            <PlanningTable
              planning={this.state.planning}
              handleDeleteBatch={this.handleDeleteBatch}
            />
          ) : (
            <Button
              onClick={this.handlePreviusPlanning}
              outline
              className='my-2 px-4'
            >
              {this.state.spinnerBtnPlan ? (
                <Spinner size='sm' color='primary' />
              ) : (
                "Vedi pianificazioni precedenti"
              )}
            </Button>
          )}
        </div>
      </CardContainerHoc>
    );
  }
}
