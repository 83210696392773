import React from "react";
import { Pie } from "react-chartjs-2";


import { Card,  CardBody, CardTitle} from 'reactstrap';

const moment = require("moment");


const MontlyData = (props) => {
        const data = props.monthlyData;
        const dataArray = data.map(item =>{
            return({
                month: item.date,
                data: {
                labels: ["Buoni", "scarti"],
                    datasets: [{
                    data: [item.good, item.refuse],
                    backgroundColor: [
                        '#ffc107',
                        '#D21919'
                    ]
                    }]
                }})
        })


    const charts = dataArray.map(element => {
         return (
            <Card key={element.month} className={"col-4"}>
                <CardTitle><h6>{element.month}</h6></CardTitle>
                <Pie
                height= {100}
                data= {element.data}
                />
            </Card>
        )
    });

    return (
        <Card className={"mt-5 mb-5"}>
            <CardTitle><h5 className={"pl-3 pt-1"}>{props.monthlyTitleDescription}{moment().format("YYYY")}, al {moment().format("D-MM")}</h5></CardTitle>
            <CardBody className={"row"}>
                {charts}
            </CardBody>
        </Card>
    )
}

export default MontlyData;
