import React, { useState, useEffect } from "react";
import moment from "moment";
import { API } from "aws-amplify";

import QualityTable from "./QualityTable";

const tableConstr = {
  type: "quality",
  title: `Prove qualità del ${moment().format("DD/MM/YYYY")}`,
  thead: [
    "Ora Prova",
    "operatore",
    "Cod. Param.",
    "macinatura",
    "diam. cialda",
    "peso registrato",
    "tempo erogazione registrato",
  ],
  action: "",
  visible: "d-none",
};

// funzione che accoda ai dati gli indici di riferimento della prova
const DailyQuality = ({ machineID }) => {
  const [testItems, setTestItems] = useState([]);
  useEffect(() => {
    console.log(`id: ${machineID}`);
    async function callApi() {
      let date = moment().format("YYYY-MM-DD");
      const payload = {
        queryStringParameters: {
          machineId: machineID,
          ts_machine: date,
        },
      };
      try {
        // carico tutte le prove e le filtro alla data odierna
        // TODO fare query filtrata
        const qualityData = await API.get(
          "apilucaffe",
          `/qualitydata/${machineID}`,
          payload
        );
        console.log(qualityData);
        const dayQualityData = qualityData.filter((item) => {
          const time = item.proof[1] ? item.proof[1].val : 2;
          if (time && time > 1) return item;
          return "";
        });
        dayQualityData.length === 0
          ? setTestItems("no data")
          : setTestItems(dayQualityData);
        console.log(testItems);
      } catch (err) {
        console.log(err);
      }
    }
    callApi();
  }, [machineID]);
  return (
    <div className={"mt-5 mb-5"}>
      <QualityTable constructor={tableConstr} data={testItems} />
    </div>
  );
};

export default DailyQuality;
