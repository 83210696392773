import React from "react";
import { Table, Card, CardTitle, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";

const TableData = ({ constructor, data, handleDelete }) => {
  let tableBody = [];
  if (data) {
    if (constructor.title === "Lista parametri") {
      tableBody = data.map((item, i) => (
        <tr key={i}>
          <td>{item.cod}</td>
          <td>{item.params.millingName}</td>
          <td>{item.params.waffelDiameter}</td>
          <td>{item.params.waffelWeight}</td>
          <td>{item.params.timeSupply}</td>
          <td className='text-center'>{item.params.grinderRoller}</td>
          <td className='text-center' onClick={() => handleDelete(item.ID)}>
            <Icon icon={close} size={24} className='text-danger' />
          </td>
        </tr>
      ));
    } else if (constructor.title === "Lista operatori") {
      tableBody = data.map((item) => (
        <tr key={item.freshman}>
          <td>{item.freshman}</td>
          <td>{item.name}</td>
          <td>{item.surname}</td>
          <td>{item.job}</td>
          <td className='text-center' onClick={() => handleDelete(item.ID)}>
            <Icon icon={close} size={24} className='text-danger' />
          </td>
        </tr>
      ));
    } else {
      return null;
    }
  }
  const table = constructor.thead.map((item) => (
    <th key={item} className='text-capitalize'>
      {item}
    </th>
  ));
  return (
    <>
      <Card className='p-4 mb-1'>
        <CardTitle>
          <h5>{constructor.title}</h5>
        </CardTitle>
        {tableBody.length > 0 ? (
          tableBody !== "null" ? (
            <>
              <Table>
                <thead>
                  <tr>{table}</tr>
                </thead>
                <tbody>{tableBody}</tbody>
              </Table>
              <div className='border-top d-flex justify-content-end'>
                <p className='pt-2 pr-3'>
                  {constructor.footer}: {data.length}
                </p>
              </div>
            </>
          ) : (
            <p className='text-center'>Nessun dato presente</p>
          )
        ) : (
          // TODO: da gestire il caso in cui non ci sono dati
          <div className='w-100 d-flex justify-content-center'>
            <Spinner size='lg' color='primary my-3' />
          </div>
        )}
      </Card>
      <Link to={{ pathname: `/impostazioni/${constructor.action}` }}>
        <Button
          outline
          color='primary'
          className={`float-right m-4 ${constructor.visible}`}
        >
          Aggiungi
        </Button>
      </Link>
    </>
  );
};

export default TableData;
