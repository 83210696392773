import React from "react";
import { Link } from "react-router-dom";

import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";

import TableData from "../../components/TableData";
import CardContainerHoc from "../../hoc/card-container-hoc";

const tableConstr = {
  title: "Lista macchine",
  thead: ["seriale", "nome", "costruttore", "ubicazione"],
  action: "addMachine",
};

const Macchine = () => {
  return (
    <CardContainerHoc title='Macchine' noBorder>
      <h1 className='mx-auto'>Under Construction ...</h1>
      {false && <TableData constructor={tableConstr} />}
    </CardContainerHoc>
  );
};

export default Macchine;
