import React from 'react';

export default function SelectOptions({optArr}){
    if(!Array.isArray(optArr)){
        return null
    }
    return (
        optArr.map((blend) => (
        <option className='text-capitalize' key={blend}>
            {blend}
        </option>
        ))
    )
    
}
