import React from "react";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

const ToastMessage = ({ backgroud, title, body }) => {
  return (
    <Toast className='float-right' style={{ position: "fixed", right: "30px" }}>
      <ToastHeader>{title}</ToastHeader>
      <ToastBody className={`bg-${backgroud} text-white`}>{body}</ToastBody>
    </Toast>
  );
};

export default ToastMessage;
