import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Modale = ({title, message, isModalOpen, toggle}) => {


  return (
    <div>
      <Modal isOpen={isModalOpen} toggle={toggle} >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {message}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Chiudi</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Modale;