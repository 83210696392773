import React from "react"
import {Link} from "react-router-dom"

import { ListGroup, ListGroupItem } from 'reactstrap'

const Impostazioni = () =>(
    <>
        <h1 className="text-center mb-4">Impostazioni</h1>

            <ListGroup>
                <Link className={"text-decoration-none"} to={{pathname: "/impostazioni/macchine"}} >
                    <ListGroupItem>
                        <h5>Macchine</h5>
                    </ListGroupItem>
                </Link>
                <Link className={"text-decoration-none"}  to={{pathname: "/impostazioni/operatori", state: {title: "Impostazione operatri"}}}>
                    <ListGroupItem>
                        <h5>Operatori</h5>
                    </ListGroupItem>
                </Link>
                <Link className={"text-decoration-none"}  to={{pathname: "/impostazioni/parametriQualita", state: {title: "Impostazione parametri qualità"}}}>
                    <ListGroupItem>
                        <h5>Parametri qualità</h5>
                    </ListGroupItem>
                </Link>
            </ListGroup>
    </>
)

export default Impostazioni;