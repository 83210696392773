import React, { Component } from "react";
import { Redirect } from "react-router";
import { utility } from "../utility/utilities";
import { readDailyHourProduct } from "../controller/dailyProductController";
import moment from "moment";

import Dailydata from "../components/Dailydata";
import DailyQuality from "../components/DailyQuality";
import Montlydata from "../components/Montlydata";
import MontlyState from "../components/MontlyState";

class ProductionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyProduction: [],
      dailyProduction: [],
      monthlyStateMachine: [],
    };
  }

  componentDidMount() {
    const today = moment().format("YYYY-MM-DD");
    const machineSerial = this.props.location.state.machine.serialNumber;
    const machineType = this.props.location.state.machine.type;
    // console.log("read data production : " + machineType);
    // console.log(this.props.location.state.machine.serialNumber);
    readDailyHourProduct(
      machineType,
      `${machineSerial}_COUNTER`,
      today,
      (data) => {
        this.setState({ dailyProduction: utility.insInDailyArray(data) });
      }
    );
  }
  componentWillUnmount() {
    this.setState({
      monthlyProduction: "",
      dailyProduction: "",
      monthlyStateMachine: "",
    });
  }

  render() {
    if (this.props.location.state) {
      return (
        <>
          <h1>
            Dettagli di produzione{" "}
            {this.props.location.state.machine.machineName} -{" "}
            {this.props.location.state.machine.serialNumber}
          </h1>
          {this.props.location.state.machine.fourDotZero ? (
            <Dailydata
              dailyProduction={this.state.dailyProduction}
              machineType={this.props.location.state.machine.type}
            />
          ) : (
            "Nessun dato di produzione per la macchina"
          )}
          {this.props.location.state.machine.type === "CA6" && (
            <DailyQuality
              machineID={this.props.location.state.machine.machineID}
            />
          )}
          {this.state.monthlyProduction.length > 0 ? (
            <Montlydata
              monthlyTitleDescription={"Produzione mensile "}
              monthlyData={this.state.monthlyProduction}
            />
          ) : null}
          {this.state.monthlyStateMachine.length > 0 ? (
            <MontlyState
              monthlyTitleDescription={"Stati macchina mensili "}
              monthlyData={this.state.monthlyStateMachine}
            />
          ) : null}
        </>
      );
    } else {
      return <Redirect to='/Dashboard' />;
    }
  }
}

export default ProductionDetails;
