export const planLabel = {
  CA6: {
    batchDescription: {
      label: "Descrizione Lotto",
      placeholder: "Inserisci la descrizione",
    },
    batchWaffleSize: {
      visible: true,
      label: "Dimensione cialda",
    },
    batchCartons: {
      visible: true,
      label: "Numero di cartoni",
      placeholder: "Inserisci il numero di cartoni",
    },
    batchEnvelopesCarton: {
      visible: true,
      label: "Numero di cialde cartone",
      placeholder: "Inserisci il numero di cialde cartone",
    },
    variants: "caffeVariants",
    optionalValidation: true,
  },
  I590: {
    batchDescription: {
      label: "Descrizione Lotto",
      placeholder: "Inserisci la descrizione",
    },
    batchWaffleSize: {
      visible: false,
      label: "Dimensione capsula",
    },
    batchCartons: {
      visible: true,
      label: "Numero di capsule",
      placeholder: "Inserisci il numero di capsule",
    },
    batchEnvelopesCarton: {
      visible: false,
      label: "",
      placeholder: "",
    },
    variants: "caffeVariants",
    optionalValidation: false,
  },
  CO1037: {
    batchDescription: {
      label: "Descrizione Lotto",
      placeholder: "Inserisci la descrizione",
    },
    batchWaffleSize: {
      visible: false,
      label: "",
    },
    batchCartons: {
      visible: true,
      label: "Numero di Barattoli",
      placeholder: "Inserisci il numero di barattoli",
    },
    batchEnvelopesCarton: {
      visible: false,
      label: "",
      placeholder: "",
    },
    optionalValidation: false,
  },
  FSH: {
    batchDescription: {
      label: "Descrizione Lotto",
      placeholder: "Inserisci la descrizione",
    },
    batchWaffleSize: {
      visible: false,
      label: "",
    },
    batchCartons: {
      visible: true,
      label: "Numero di Barattoli",
      placeholder: "Inserisci il numero di barattoli",
    },
    batchEnvelopesCarton: {
      visible: false,
      label: "",
      placeholder: "",
    },
    variants: "caffeVariants",
    optionalValidation: false,
  },
  GOGL: {
    batchDescription: {
      label: "Descrizione Lotto",
      placeholder: "Inserisci la descrizione",
    },
    batchWaffleSize: {
      visible: false,
      label: "",
    },
    batchCartons: {
      visible: true,
      label: "Numero di Sacchetti da produrre",
      placeholder: "Inserisci il numero di sacchetti",
    },
    batchEnvelopesCarton: {
      visible: false,
      label: "",
      placeholder: "",
    },
    blend: {
      label: "Nome ricetta",
    },
    variants: "gogl",
    optionalValidation: false,
  },
  NR30: {
    batchDescription: {
      label: "Descrizione Lotto",
      placeholder: "Inserisci la descrizione",
    },
    batchWaffleSize: {
      visible: false,
      label: "Dimensione capsula",
    },
    batchCartons: {
      visible: true,
      label: "Numero di capsule",
      placeholder: "Inserisci il numero di capsule",
    },
    batchEnvelopesCarton: {
      visible: false,
      label: "",
      placeholder: "",
    },
    variants: "caffeVariants",
    optionalValidation: false,
  },
};

export const productionTableLabel = {
  CA6: {
    graphProductionLabel: "Cialde Prodotte",
    tableProductionLabel: "numero cialde prodotte: ",
    noProductionLabel: "Nessuna cialda prodotta nella giornata ",
  },
  FSH: {
    graphProductionLabel: "Barattoli Prodotti",
    tableProductionLabel: "numero barattoli prodotti: ",
    noProductionLabel: "Nessun barattolo prodotto nella giornata ",
  },
  CO1037: {
    graphProductionLabel: "Pezzi Prodotti",
    tableProductionLabel: "numero pezzi prodotti: ",
    noProductionLabel: "Nessun pezzo prodotto nella giornata ",
  },
  I590: {
    graphProductionLabel: "Capsule Prodotte",
    tableProductionLabel: "numero capsule prodotte: ",
    noProductionLabel: "Nessuna capsula prodotta nella giornata ",
  },
  NR30: {
    graphProductionLabel: "Tostature",
    tableProductionLabel: "numero tostature: ",
    noProductionLabel: "Nessuna nostatura nella giornata ",
  },
  GOGL: {
    graphProductionLabel: "Sacchetti prodotti",
    tableProductionLabel: "numero sacchetti: ",
    noProductionLabel: "Nessuna produzione nella giornata ",
  },
  prisma: {
    graphProductionLabel: "Sacchetti verificati",
    tableProductionLabel: "numero sacchetti: ",
    noProductionLabel: "Nessuna verifica nella giornata ",
  },
};
