import React from "react";
import ReducerCard from "./ReducerCard";
import { utility } from "../utility/utilities";

const DailyQualityReducer = ({ data }) => {
  // funzione che restituisce i risultati accorpati delle prove
  const results = utility.qualityScoreForMilling(data);

  return results.map((result, i) => {
    const erogazione = result.score.cron;
    const pesata = result.score.balance;
    return (
      <div key={i}>
        <h3>
          <span className='font-weight-light'> Nome prova:</span>{" "}
          {result.millingName}{" "}
        </h3>
        <div className='row mb-3'>
          <ReducerCard
            data={pesata}
            title='peso cialda'
            labelMed='Peso medio:'
          />
          <ReducerCard
            data={erogazione}
            title='tempo di Erogazione'
            labelMed='Secondi medi:'
          />
        </div>
      </div>
    );
  });
};

export default DailyQualityReducer;
