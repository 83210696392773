import React from "react";

import Menu from "./Menu";
import Logo from "../assets/img/logo_lucaffe_rosso.png";

const Header = () => {
  return (
    <div className='navbar navbar-expand-lg navbar-dark bg-warning mb-4'>
      <img
        src={Logo}
        className='p-2'
        alt='logo'
        style={{ width: 120 + "px" }}
      />
      <p className='text-muted'>v 1.3.0</p>
      <Menu />
    </div>
  );
};

export default Header;
