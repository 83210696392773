import React from "react";
import { Bar } from "react-chartjs-2";

import { Card, CardTitle } from "reactstrap";
import { productionTableLabel } from "../utility/dict";

const moment = require("moment");

const DailyData = (props) => {
  console.log(props);
  let good = [],
    refuse = [],
    totalProduction;
  // AlbaTODO add label parse function labelParse(context, type) return labelObj
  if (props.dailyProduction) {
    const production = props.dailyProduction;
    if (production.length) {
      totalProduction = production.reduce((a, b) => parseInt(a) + parseInt(b));
      console.log("total: " + totalProduction);
      good = props.dailyProduction;
    }
  }
  const chartData = {
    labels: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    datasets: [
      {
        label: productionTableLabel[props.machineType]
          ? productionTableLabel[props.machineType].graphProductionLabel
          : "Cialde Prodotte",
        backgroundColor: "#ffc107",
        data: good,
      },
      {
        label: "Scarti",
        backgroundColor: "#D21919",
        data: refuse,
      },
    ],
  };

  return (
    <Card className={"my-5"}>
      <CardTitle>
        <h5 className={"pl-3 pt-1"}>
          Produzione giornaliera del{" "}
          {props.productionDate
            ? moment(props.productionDate).format("DD/MM/YYYY")
            : moment().format("DD/MM/YYYY")}
        </h5>
        <h6 className={"pl-3 pt-1"}>
          {productionTableLabel[props.machineType].tableProductionLabel}{" "}
          {totalProduction}{" "}
        </h6>
      </CardTitle>
      {totalProduction === 0 ? (
        <p className={"w-100 text-center my-5"}>
          {productionTableLabel[props.machineType].noProductionLabel}
        </p>
      ) : (
        <Bar height={100} data={chartData} />
      )}
    </Card>
  );
};

export default DailyData;
