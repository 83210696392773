import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//import AWS auth
import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";
import Header from "../components/Header";
import Dashboard from "../views/Dashboard";
import StoricoProd from "../views/StoricoProd";
import Storico from "../views/Storico";
import StoricoQual from "../views/StoricoQual";
import Impostazioni from "../views/Impostazioni";
import Operatori from "../views/settings/Operatori";
import Macchine from "../views/settings/Macchine";
import QualityParams from "../views/settings/QualityParams";
import AddRecipe from "../views/AddRecipe";
import AddOperator from "../views/AddOperator";
import ProductionDetails from "../views/ProductionDetails";
import ProductionPlanning from "../views/ProductionPlanning";
import { I18n } from "aws-amplify";
import it_lang from '../utility/translactions/it';
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifyRequireNewPassword,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import Background from '../assets/img/cuore.jpeg';
I18n.putVocabulariesForLanguage("it-IT", it_lang);

Amplify.configure(awsconfig);

const AppRouter = () => (
  <BrowserRouter>
    <div>
      <Header />
      <div className='container'>
        <Switch>
          <Route path='/' component={Dashboard} exact />
          <Route path='/storico' exact component={Storico} />
          <Route path='/impostazioni' exact component={Impostazioni} />
          <Route path='/impostazioni/operatori' component={Operatori} />
          <Route path='/impostazioni/macchine' component={Macchine} />
          <Route
            path='/impostazioni/parametriQualita'
            component={QualityParams}
          />
          <Route path='/impostazioni/nuovo/parametro' component={AddRecipe} />
          <Route path='/impostazioni/nuovo/operatore' component={AddOperator} />
          <Route path='/storico/produzione' exact component={StoricoProd} />
          <Route path='/storico/qualità' component={StoricoQual} />
          <Route path='/dettagli_produzione' component={ProductionDetails} />
          <Route
            path='/pianificazione_produzione'
            component={ProductionPlanning}
          />
          <Route component={Dashboard} />
        </Switch>
      </div>
    </div>
  </BrowserRouter>
);
//TODO aggiungere tema pagina psw
const customTheme = {};

const AppAuth = ()=>(

  <AmplifyAuthenticator >
    <div className="loginPage" slot="sign-in">
      <div className="loginFormContainer">
         <AmplifySignIn 
            slot="sign-in"
            hide-sign-up="true"
            formFields={[
                {
                  type: "username",

                },{type:"password"}]}
      />  
      </div>
     
          <div style={{
            backgroundImage:`url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize:"cover",
            width: "60%",
            height:"100%"
          }}> 
          
          
          </div>
    </div>
     
    
    <AppRouter/>
    {/* <AmplifyForgotPassword theme={customTheme} />
    <AmplifyRequireNewPassword /> */}
    
  </AmplifyAuthenticator>
  
)

export default AppAuth